function createObject() {
  let example = {
    id: "LBST_ZV_Schieberegler_Brueche",
    workOrder:
      "Ordnen Sie die Brüche an der richtigen Stelle auf dem Zahlenstrahl ein.",
    originalSlideWidth: 750,
    originalSlideHeight: 400,
    slides: [
      {
        image: require("@/assets/zv/Schieberegler/step0_Brueche.png"),
      },
      {
        animation: require('@/assets/zv/Schieberegler/step1_animation_Brueche.webm'),
        animationAlt: require('@/assets/zv/Schieberegler/step1_animation_Brueche.mp4'),
        intervention: true,
        interventionText:
          "Wo ist der Bruch  einzuordnen? Wählen Sie den korrekten Zahlenstrahl aus.",
        interventionOptions: [
          {
            title: "A",
            correct: true,
            //hintText: "Prima! Der Bruch ¾ ist hier im Zahlenstrahl einzuordnen. Da der Bruch die Dezimalzahl 0,75 darstellt wird er in der Mitte von 0,5 und 1 auf dem Zahlenstrahl eingeordnet. Die 1 wird durch den Bruch in 4 Teile geteilt. Durch die 3 im Zähler wird angegeben, dass 3 Teile vorhanden sind.",
            top: 50,
            left: 415,
            width: 240,
            height: 77,
          },
          {
            title: "B",
            correct: false,
            hintText:
              "Hier ist der Bruch nicht einzuordnen. Versuchen Sie sich vorzustellen in wie viele Teile der Bruch die 1 teilt und wie viele dieser Teile vorhanden sind. Dies kann Ihnen helfen den Bruch auf dem Zahlenstrahl besser einzuordnen.",
            top: 140,
            left: 415,
            width: 240,
            height: 77,
          },
          {
            title: "C",
            correct: false,
            hintText:
              "Hier ist der Bruch nicht einzuordnen. Versuchen Sie sich vorzustellen in wie viele Teile der Bruch die 1 teilt und wie viele dieser Teile vorhanden sind. Dies kann Ihnen helfen den Bruch auf dem Zahlenstrahl besser einzuordnen.",
            top: 235,
            left: 415,
            width: 240,
            height: 77,
          },
        ],
      },
      {
        animation: require('@/assets/zv/Schieberegler/step2_animation_Brueche.webm'),
        animationAlt: require('@/assets/zv/Schieberegler/step2_animation_Brueche.mp4'),
        successText:
          "Prima! Der Bruch ¾ ist hier im Zahlenstrahl einzuordnen. Da der Bruch die Dezimalzahl 0,75 darstellt wird er in der Mitte von 0,5 und 1 auf dem Zahlenstrahl eingeordnet. Die 1 wird durch den Bruch in 4 Teile geteilt. Durch die 3 im Zähler wird angegeben, dass 3 Teile vorhanden sind.",
      },
      {
        animation: require("@/assets/zv/Schieberegler/step3_animation_Brueche.webm"),
        animationAlt: require("@/assets/zv/Schieberegler/step3_animation_Brueche.mp4"),
        intervention: true,
        interventionText:
          "Wie würden sie den Bruch  in den bereits bestehenden Zahlenstrahl einordnen? Wählen Sie aus.",
        interventionOptions: [
          {
            title: "A",
            correct: false,
            hintText:
              "Hier ist der Bruch nicht einzuordnen. Versuchen Sie sich vorzustellen in wie viele Teile der Bruch die 1 teilt und wie viele dieser Teile vorhanden sind. Dies kann Ihnen helfen den Bruch auf dem Zahlenstrahl besser einzuordnen.",
            top: 50,
            left: 415,
            width: 270,
            height: 77,
          },
          {
            title: "B",
            correct: true,
            //hintText: "Prima! Der Bruch 7/5 ist hier im Zahlenstrahl einzuordnen. Da der Bruch die Dezimalzahl 1,4 darstellt wird er auf dem vierten Strich nach dem großen Strich für die 1 auf dem Zahlenstrahl eingeordnet. Die 1 wird durch den Bruch in 5 Teile geteilt. Durch die 7 im Zähler wird angegeben, dass 7 Teile vorhanden sind.",
            top: 140,
            left: 415,
            width: 270,
            height: 77,
          },
          {
            title: "C",
            correct: false,
            hintText:
              "Hier ist der Bruch nicht einzuordnen. Versuchen Sie sich vorzustellen in wie viele Teile der Bruch die 1 teilt und wie viele dieser Teile vorhanden sind. Dies kann Ihnen helfen den Bruch auf dem Zahlenstrahl besser einzuordnen. ",
            top: 235,
            left: 415,
            width: 270,
            height: 77,
          },
        ],
      },
      {
        animation: require("@/assets/zv/Schieberegler/step4_animation_Brueche.webm"),
        animationAlt: require("@/assets/zv/Schieberegler/step4_animation_Brueche.mp4"),
        successText:
          "Prima! Der Bruch 7/5 ist hier im Zahlenstrahl einzuordnen. Da der Bruch die Dezimalzahl 1,4 darstellt wird er auf dem vierten Strich nach dem großen Strich für die 1 auf dem Zahlenstrahl eingeordnet. Die 1 wird durch den Bruch in 5 Teile geteilt. Durch die 7 im Zähler wird angegeben, dass 7 Teile vorhanden sind.",
      },
      {
        animation: require("@/assets/zv/Schieberegler/step5_animation_Brueche.webm"),
        animationAlt: require("@/assets/zv/Schieberegler/step5_animation_Brueche.mp4"),
        intervention: true,
        interventionText:
          "Wie muss der Bruch  in den bereits bestehenden Zahlenstrahl eingeordnet werden? Wählen Sie den korrekten Zahlenstrahl aus.",
        interventionOptions: [
          {
            title: "A",
            correct: true,
            //hintText: "Prima! Der Bruch 1/6 ist hier im Zahlenstrahl einzuordnen. Da der Bruch ungefähr die Dezimalzahl 0,16667 darstellt wird er zwischen dem ersten und zweiten kleinen Strich leicht rechts von der Mitte dieser eingeordnet.",
            top: 50,
            left: 415,
            width: 260,
            height: 77,
          },
          {
            title: "B",
            correct: false,
            hintText:
              "Hier ist der Bruch nicht einzuordnen. Versuchen Sie sich vorzustellen in wie viele Teile der Bruch die 1 teilt und wie viele dieser Teile vorhanden sind. Dies kann Ihnen helfen den Bruch auf dem Zahlenstrahl besser einzuordnen. Die 1 wird durch den Bruch in 6 Teile geteilt. Durch die 1 im Zähler wird angegeben, dass 1 Teil vorhanden ist.",
            top: 140,
            left: 415,
            width: 260,
            height: 77,
          },
          {
            title: "C",
            correct: false,
            hintText:
              "Hier ist der Bruch nicht einzuordnen. Versuchen Sie sich vorzustellen in wie viele Teile der Bruch die 1 teilt und wie viele dieser Teile vorhanden sind. Dies kann Ihnen helfen den Bruch auf dem Zahlenstrahl besser einzuordnen.",
            top: 235,
            left: 415,
            width: 260,
            height: 77,
          },
        ],
      },
      {
        animation: require("@/assets/zv/Schieberegler/step6_animation_Brueche.webm"),
        animationAlt: require("@/assets/zv/Schieberegler/step6_animation_Brueche.mp4"),
        successText:
          "Prima! Der Bruch 1/6 ist hier im Zahlenstrahl einzuordnen. Da der Bruch ungefähr die Dezimalzahl 0,16667 darstellt wird er zwischen dem ersten und zweiten kleinen Strich leicht rechts von der Mitte dieser eingeordnet.",
      },
    ],
  };
  return example;
}

export default createObject();
