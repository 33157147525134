<template>
  <div>
    <h2 class="faledia-caption">Zusammenhänge zwischen Zahlen</h2>
    <v-card outlined>
      <v-card-title>Was sollen Schüler:innen lernen?</v-card-title>
      <v-card-text>
        Die Kinder können…
        <ul>
          <li>
            Zahlzerlegungen vornehmen und Beziehungen zwischen Teilen und dem
            Ganzen erkunden.
          </li>
          <li>Anzahlen vergleichen.</li>
          <li>Zahlen im Bezug zu Nachbarzahlen und -stellen betrachten.</li>
        </ul>
        Und diese Einsichten für vorteilhaftes Rechnen nutzen.
      </v-card-text>
    </v-card>
    <br />
    <p>
      Ein wesentlicher Aspekt des Zahlverständnisses ist es, Beziehungen
      zwischen Zahlen zu ergründen und für vorteilhaftes Rechnen zu nutzen (MSW
      NRW, 2008). Dies ermöglicht den Lernenden nicht nur, die ihnen bekannten
      Rechenstrategien sinnvoll anwenden zu können: Sie können sich auf
      vorangegangene Aufgaben beziehen und die Erkenntnisse aus diesen Aufgaben
      für die aktuelle Rechnung nutzen.
    </p>
    <p>Schuler (2013) unterscheidet zwischen drei Schwerpunkten:</p>
    <ol>
      <li>Teil-Ganzes-Konzept</li>
      <li>(An-)zahlen vergleichen</li>
      <li>Zahlen positionieren</li>
    </ol>
    <p>
      Eine Menge als Zusammensetzung aus mehreren Teilmengen zu verstehen, wird
      als Teil-Ganzes-Konzept bezeichnet. Auf dieser Grundlage können Mengen in
      verschiedene Anzahlen zerlegt und wieder zusammengesetzt werden (Scherer &
      Moser-Opitz, 2010). Dieses kardinale Verständnis zu erfassen ist somit
      eine wichtige Erwerbsaufgabe für die Ausbildung eines Zahlverständnisses.
    </p>
    <p>
      Eine wichtige Erkenntnis, die die Lernenden im Zusammenhang mit diesem
      Konzept sammeln müssen, ist, dass sich die Wertigkeit einer Menge nicht
      verändert, wenn sie in Teilmengen zerlegt wird (Häsel-Weide, 2016). Im
      Zusammenhang mit dieser Einsicht steht die Erkenntnis, dass sich durch die
      Veränderung einer der Teilmengen die andere auf eine vorhersagbare Weise
      verändert.
    </p>
    <p>
      Neben dem Verständnis für das Teil-Ganzes-Konzept ist auch die Fähigkeit
      zentral, Anzahlen zu vergleichen, also Differenzmengen zu bestimmen. Auf
      dieser Erkenntnis fußt beispielsweise das Konstanzgesetz der Subtraktion,
      nach welchem die Differenz konstant bleibt, wenn Subtrahend und Minuend um
      den gleichen Wert gleichsinnig verändert werden.
    </p>
    <p>
      Erste einfache Mengenvergleiche erfolgen bereits im Vorschulalter.
      Mithilfe von Wörtern wie „mehr“ oder „weniger“ können die größere und die
      kleinere Teilmenge bereits hier voneinander unterschieden werden (Resnick,
      1983). Um Mengen miteinander zu vergleichen, können zwei Vorgehensweisen
      unterschieden werden. Beim Abzählen werden die Elemente beider Mengen
      abgezählt und die Zahlwörter werden miteinander verglichen. Hier steht
      somit der ordinale Zahlaspekt im Fokus. Bei der 1-zu-1-Zuordnung hingegen
      wird jedem Element der einen Menge ein Element der anderen Menge
      zugeordnet. Auf diese Weise kann sowohl zwischen größerer und kleinerer
      Menge unterschieden als auch die Differenzmenge bestimmt werden.
    </p>
    <div class="image">
      <AppExpandableImage
        contain
        :src="require('@/assets/zv/zusammenhaenge.png')"
        parentId="zv"
        elementId="zv_zusammenhaenge.png"
      >
      </AppExpandableImage>
    </div>
    <br />
    <p>
      Um ein Verständnis für Zahlbeziehungen zu entwickeln, sollten auch
      ordinale Beziehungen in den Fokus gerückt werden, also solche Beziehungen,
      bei denen eine Rangordnung der Zahlen von Bedeutung ist. Auf diese Weise
      können Nachbarzahlen (z. B. Vorgänger und Nachfolger) identifiziert
      werden. Ebenso können die nächstgrößeren Stellenwerte in den Blick
      genommen werden (z. B. Nachbarhunderter).
    </p>

    <div style="background-color: white">
      <LernbausteinSchieberegler
        :id="Schieberegler.id"
        :workOrder="Schieberegler.workOrder"
        :originalSlideWidth="Schieberegler.originalSlideWidth"
        :originalSlideHeight="Schieberegler.originalSlideHeight"
        :slides="Schieberegler.slides"
      >
      <template v-slot:alternative-content>
        <p>Überlegen Sie immer in wie viele Teile der Bruch die 1 teilt und wie viele Teile davon im Bruch vorhanden sind. Dies kann Ihnen helfen die Brüche auf dem Zahlenstrahl einzuordnen.</p>
      </template>
      </LernbausteinSchieberegler>
    </div>
    <p>
      In dem Lernbaustein konnten Sie anhand der Einordnung der Brüche erkennen,
      wie schwierig es für Kind zu Beginn ist Zahlen miteinander in Beziehung zu
      setzen. Für Kinder, die im Bereich der Natürlichen Zahlen noch nicht so
      sicher sind, ist die Einordung eine Herausforderung. Zur Förderung einer
      tragfähigen Zahlvorstellung ist dies aber eine wichtige Facette, die
      erlernt werden sollte.
    </p>

    <AppLiteraturZV />
    <AppBottomNavZV
      next="/zahlverstaendnis/uebergang"
      back="/zahlverstaendnis/hintergrundwissen/darstellungen"
    />
  </div>
</template>

<script>
import AppBottomNavZV from "@/common/AppBottomNavZV";
import AppLiteraturZV from "@/common/AppLiteraturZV";
import AppExpandableImage from "@/common/AppExpandableImage";
import LernbausteinSchieberegler from "@/components/faledia/explorativeElemente/LernbausteinSchieberegler";

import LBST_Schieberegler_Brueche from "@/components/faledia/seiten/zahlverstaendnis/LBST/LBST_ZV_Schieberegler_Brueche";

export default {
  components: {
    AppBottomNavZV,
    AppLiteraturZV,
    AppExpandableImage,
    LernbausteinSchieberegler,
  },
  data: () => ({
    Schieberegler: LBST_Schieberegler_Brueche,
  }),
};
</script>

<style scoped>
.image {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}
</style>
